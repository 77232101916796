.group {
  padding: 44px 25px 0;

  @include mq(md) {
    @include container();

    padding: 0;
  }

  &__items {
    margin: 28px 0 0;

    @include mq(md) {
      margin: 60px 0 0;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    @include mq(md) {
      margin: 0 -19px;
    }
  }

  &__item {
    width: 50%;
    padding: 0 10px;

    @include mq(md) {
      width: 25%;
      padding: 0 19px;
    }

    &:nth-child(n + 3) {
      margin: 21px 0 0;

      @include mq(md) {
        margin: 0;
      }
    }

    &:nth-child(n + 5) {
      @include mq(md) {
        margin: 45px 0 0;
      }
    }
  }

  &__link {
    img {
      width: 100%;
    }
  }

  &__logo {
    position: relative;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: '';
      background-image: linear-gradient(90deg, rgb(0, 82, 173) 0, rgb(0, 173, 239) 100%); // stylelint-disable-line

      @include mq(md) {
        height: 2px;
      }
    }
  }

  &__heading {
    margin: 10px 0 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.3;

    @at-root .group__item:not(:nth-child(n + 7)) & {
      min-height: 32px;

      @include mq(md) {
        min-height: auto;
      }
    }

    @at-root a.group__link:hover & {
      color: #0052ad;
    }

    @include mq(md) {
      margin: 25px 0 0;
      font-size: 15px;
      transition: color 300ms;
    }
  }

  &__heading-text {
    padding-right: 6px;

    @include mq(md) {
      padding-right: 15px;
    }

    + .icon {
      position: relative;
      display: inline-block;
      width: 9px;
      height: 9px;
      background-image: url('/assets/images/pages/common/icon_blank.png');
      background-size: contain;

      @include mq(md) {
        top: 1px;
        width: 14px;
        height: 14px;
      }
    }
  }

  &__text {
    margin: 10px 0 0;
    font-size: 10px;
    line-height: 1.65;
    color: rgba(34, 34, 34, 0.7);

    @include mq(md) {
      margin: 12px 0 0;
      font-size: 12px;
      line-height: 2;
    }
  }
}
