.mv {
  padding-left: 40px;

  @include mq(md) {
    padding-left: 150px;
  }

  &__inner {
    position: relative;
  }

  &__image {
    padding-top: 128.95%;
    background-image: url('/assets/images/pages/index/mv_image_sm.jpg');
    background-size: cover;

    @include mq(md) {
      width: 100%;
      height: 753px;
      padding-top: 0;
      background-image: url('/assets/images/pages/index/mv_image_md.jpg');
    }
  }

  &__image-text {
    display: none;

    @include mq(md) {
      position: absolute;
      top: 273px;
      left: 8%;
      display: block;
    }

    img {
      @include mq(md) {
        width: 551px;
        height: 140px;
      }
    }
  }
}
