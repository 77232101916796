.business {
  position: relative;
  padding: 0 25px;

  @include mq(md) {
    padding: 0;
  }

  &__inner {
    @include mq(md) {
      max-width: 1500px;
      margin: 0 auto;
      overflow: hidden;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 60px 0 80px;
    margin: 0 -13px;

    @include mq(md) {
      flex-wrap: nowrap;
      padding: 152px 0;
      margin: 0 -26px;
    }
  }

  &__item {
    width: 50%;
    padding: 0 13px;

    @include mq(md) {
      width: 25%;
      padding: 0 26px;
    }

    &:nth-child(n + 3) {
      margin: 35px 0 0;

      @include mq(md) {
        margin: 0;
      }
    }
  }

  &__link {
    display: block;
    min-height: 106px;

    @include mq(md) {
      min-height: 0;
    }
  }

  &__icon {
    position: relative;
    left: 50%;
    display: inline-block;
    width: 27px;
    height: 29px;
    transform: translateX(-50%);

    @include mq(md) {
      width: 38px;
      height: 42px;
    }

    &--human {
      background-image: url('/assets/images/pages/index/icon_human.svg');
    }

    &--peace {
      background-image: url('/assets/images/pages/index/icon_peace.svg');
    }

    &--site {
      background-image: url('/assets/images/pages/index/icon_site.svg');
    }

    &--document {
      background-image: url('/assets/images/pages/index/icon_document.svg');
    }
  }

  &__text {
    margin: 13px 0 0;
    font-size: 14px;
    line-height: 1.4;
    color: #fff;
    text-align: center;
    letter-spacing: 0.07em;

    @include mq(md) {
      margin: 35px 0 0;
      font-size: 16px;
    }
  }

  &__number {
    position: relative;
    padding: 3px 0 10px;
    line-height: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    @include mq(md) {
      padding: 25px 0 15px 4px;
    }

    &::before {
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 15px;
      height: 1px;
      content: '';
      background-color: #fff;

      @at-root .business__link:hover & {
        @include mq(md) {
          width: 100%;
        }
      }

      @include mq(md) {
        width: 19px;
        transition: width 300ms;
      }
    }

    img {
      width: 12px;
    }
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    background-size: contain;
  }

  &::before {
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 173%;
    background-image: linear-gradient(90deg, rgb(0, 82, 173) 0, rgb(0, 173, 239) 100%); // stylelint-disable-line

    @include mq(md) {
      bottom: -235px;
      height: 235%;
    }
  }

  &::after {
    right: 0;
    bottom: -35px;
    z-index: -1;
    width: 266px;
    height: 359px;
    background-image: url('/assets/images/pages/index/business_obj_sm.png');
    background-size: contain;

    @include mq(md) {
      right: 0;
      bottom: -425px;
      width: 570px;
      height: 717px;
      background-image: url('/assets/images/pages/index/business_obj_md.png');
    }
  }
}
