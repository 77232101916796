.recruit {
  padding: 58px 25px 0;
  overflow: hidden;

  @include mq(md) {
    position: relative;
    padding: 430px 0 128px;
  }

  &__inner {
    @include mq(md) {
      @include container();
    }
  }

  &__contents {
    @include mq(md) {
      width: 50%;
    }
  }

  &__heading {
    text-align: center;

    @include mq(md) {
      text-align: left;
    }

    img {
      width: 55px;

      @include mq(md) {
        width: 76px;
      }
    }
  }

  &__copy {
    margin: 12px 0 0;
    font-size: 24px;
    line-height: 1.33;
    text-align: center;

    @include mq(md) {
      margin: 33px 0 0;
      font-size: 32px;
      line-height: 1.46;
      text-align: left;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 45px;
    padding-bottom: 1px;
    margin: 23px auto 0;
    font-size: 14px;
    color: #fff;
    background-image: linear-gradient(90deg, rgb(0, 82, 173) 0, rgb(0, 173, 239) 100%); // stylelint-disable-line

    @include mq(md) {
      position: relative;
      z-index: 1;
      width: 212px;
      height: 60px;
      margin: 50px 0 0;
    }

    &::before {
      @at-root .recruit__button:hover::before {
        @include mq(md) {
          opacity: 1;
        }
      }

      @include mq(md) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        content: '';
        background-image: linear-gradient(90deg, rgb(0, 82, 173) 30%, rgb(0, 173, 239) 100%); // stylelint-disable-line
        opacity: 0;
        transition: opacity 450ms;
      }
    }
  }

  &__image {
    position: relative;
    height: 206px;
    margin: 52px -25px 0;
    background-color: #e2eef8;

    @include mq(md) {
      height: 502px;
    }

    @include mq(md) {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      margin: 0;
    }

    &::before {
      position: absolute;
      right: -16px;
      bottom: 0;
      width: 373px;
      height: 253px;
      content: '';
      background-image: url('/assets/images/pages/index/recruit_image.png');
      background-size: contain;

      @include mq(md) {
        right: 55px;
        width: 895px;
        height: 607px;
      }
    }

    img {
      width: 100%;
    }
  }
}
