.news {
  padding: 47px 25px 0;

  @include mq(md) {
    @include container();

    display: flex;
    justify-content: space-between;
    padding: 140px 0 65px;
  }

  &__contents {
    @include mq(md) {
      width: 100%;
      margin-left: 198px;
    }
  }

  &__list {
    margin: 30px 0 0;

    @include mq(md) {
      margin: 0;
    }
  }

  &__item {
    border-top: 1px solid #d9d9d9;

    &:last-child {
      border-bottom: 1px solid #d9d9d9;
    }
  }

  &__link {
    position: relative;
    display: block;
    padding: 17px 35px 21px 0;

    @include mq(md) {
      display: flex;
      align-items: center;
      padding: 27px 70px 28px 28px;
      transition: color 300ms;
    }

    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 14px;
      height: 8px;
      content: '';
      background-image: url('/assets/images/pages/common/icon_arrow.svg');
      transform: translateY(-50%);

      @at-root .news__link:hover::before {
        @include mq(md) {
          right: 27px;
        }
      }

      @include mq(md) {
        right: 32px;
        transition: right 300ms;
      }
    }

    &:hover {
      @include mq(md) {
        color: #0052ad;
      }
    }
  }

  &__time {
    font-size: 10px;
    font-style: italic;
    letter-spacing: 0.07em;

    @include mq(md) {
      font-size: 12px;
    }
  }

  &__text {
    margin: 4px 0 0;
    font-size: 12px;

    @include mq(md) {
      margin: 0 0 0 43px;
      font-size: 14px;
    }
  }

  &__text-link {
    margin: 22px 0 0;
    font-size: 14px;
    text-align: right;

    @include mq(md) {
      margin: 28px 0 0;
    }

    > a {
      position: relative;

      &::before {
        position: absolute;
        top: 8px;
        left: -18px;
        content: '';
        border-color: transparent transparent transparent #0052ad;
        border-style: solid;
        border-width: 3px 0 3px 5px;

        @include mq(md) {
          top: 7px;
          border-width: 4px 0 4px 6px;
        }
      }

      &:hover {
        @include mq(md) {
          text-decoration: underline;
        }
      }
    }
  }

  .c-heading {
    @include mq(md) {
      margin-top: -13px;
    }
  }
}
