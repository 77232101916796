.business {
  position: relative;
  padding: 0 25px;
}
@media (min-width: 48em) {
  .business {
    padding: 0;
  }
}
@media (min-width: 48em) {
  .business__inner {
    max-width: 1500px;
    margin: 0 auto;
    overflow: hidden;
  }
}
.business__list {
  display: flex;
  flex-wrap: wrap;
  padding: 60px 0 80px;
  margin: 0 -13px;
}
@media (min-width: 48em) {
  .business__list {
    flex-wrap: nowrap;
    padding: 152px 0;
    margin: 0 -26px;
  }
}
.business__item {
  width: 50%;
  padding: 0 13px;
}
@media (min-width: 48em) {
  .business__item {
    width: 25%;
    padding: 0 26px;
  }
}
.business__item:nth-child(n+3) {
  margin: 35px 0 0;
}
@media (min-width: 48em) {
  .business__item:nth-child(n+3) {
    margin: 0;
  }
}
.business__link {
  display: block;
  min-height: 106px;
}
@media (min-width: 48em) {
  .business__link {
    min-height: 0;
  }
}
.business__icon {
  position: relative;
  left: 50%;
  display: inline-block;
  width: 27px;
  height: 29px;
  transform: translateX(-50%);
}
@media (min-width: 48em) {
  .business__icon {
    width: 38px;
    height: 42px;
  }
}
.business__icon--human {
  background-image: url("/assets/images/pages/index/icon_human.svg");
}
.business__icon--peace {
  background-image: url("/assets/images/pages/index/icon_peace.svg");
}
.business__icon--site {
  background-image: url("/assets/images/pages/index/icon_site.svg");
}
.business__icon--document {
  background-image: url("/assets/images/pages/index/icon_document.svg");
}
.business__text {
  margin: 13px 0 0;
  font-size: 14px;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  letter-spacing: 0.07em;
}
@media (min-width: 48em) {
  .business__text {
    margin: 35px 0 0;
    font-size: 16px;
  }
}
.business__number {
  position: relative;
  padding: 3px 0 10px;
  line-height: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
@media (min-width: 48em) {
  .business__number {
    padding: 25px 0 15px 4px;
  }
}
.business__number::before {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 15px;
  height: 1px;
  content: "";
  background-color: #fff;
}
@media (min-width: 48em) {
  .business__link:hover .business__number::before {
    width: 100%;
  }
}

@media (min-width: 48em) {
  .business__number::before {
    width: 19px;
    transition: width 300ms;
  }
}
.business__number img {
  width: 12px;
}
.business::before, .business::after {
  position: absolute;
  content: "";
  background-size: contain;
}
.business::before {
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 173%;
  background-image: linear-gradient(90deg, #0052ad 0, #00adef 100%);
}
@media (min-width: 48em) {
  .business::before {
    bottom: -235px;
    height: 235%;
  }
}
.business::after {
  right: 0;
  bottom: -35px;
  z-index: -1;
  width: 266px;
  height: 359px;
  background-image: url("/assets/images/pages/index/business_obj_sm.png");
  background-size: contain;
}
@media (min-width: 48em) {
  .business::after {
    right: 0;
    bottom: -425px;
    width: 570px;
    height: 717px;
    background-image: url("/assets/images/pages/index/business_obj_md.png");
  }
}

.group {
  padding: 44px 25px 0;
}
@media (min-width: 48em) {
  .group {
    width: 1200px;
    margin: 0 auto;
    padding: 0;
  }
}
.group__items {
  margin: 28px 0 0;
}
@media (min-width: 48em) {
  .group__items {
    margin: 60px 0 0;
  }
}
.group__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
@media (min-width: 48em) {
  .group__list {
    margin: 0 -19px;
  }
}
.group__item {
  width: 50%;
  padding: 0 10px;
}
@media (min-width: 48em) {
  .group__item {
    width: 25%;
    padding: 0 19px;
  }
}
.group__item:nth-child(n+3) {
  margin: 21px 0 0;
}
@media (min-width: 48em) {
  .group__item:nth-child(n+3) {
    margin: 0;
  }
}
@media (min-width: 48em) {
  .group__item:nth-child(n+5) {
    margin: 45px 0 0;
  }
}
.group__link img {
  width: 100%;
}
.group__logo {
  position: relative;
}
.group__logo::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  background-image: linear-gradient(90deg, #0052ad 0, #00adef 100%);
}
@media (min-width: 48em) {
  .group__logo::before {
    height: 2px;
  }
}
.group__heading {
  margin: 10px 0 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
}
.group__item:not(:nth-child(n+7)) .group__heading {
  min-height: 32px;
}
@media (min-width: 48em) {
  .group__item:not(:nth-child(n+7)) .group__heading {
    min-height: auto;
  }
}

a.group__link:hover .group__heading {
  color: #0052ad;
}

@media (min-width: 48em) {
  .group__heading {
    margin: 25px 0 0;
    font-size: 15px;
    transition: color 300ms;
  }
}
.group__heading-text {
  padding-right: 6px;
}
@media (min-width: 48em) {
  .group__heading-text {
    padding-right: 15px;
  }
}
.group__heading-text + .icon {
  position: relative;
  display: inline-block;
  width: 9px;
  height: 9px;
  background-image: url("/assets/images/pages/common/icon_blank.png");
  background-size: contain;
}
@media (min-width: 48em) {
  .group__heading-text + .icon {
    top: 1px;
    width: 14px;
    height: 14px;
  }
}
.group__text {
  margin: 10px 0 0;
  font-size: 10px;
  line-height: 1.65;
  color: rgba(34, 34, 34, 0.7);
}
@media (min-width: 48em) {
  .group__text {
    margin: 12px 0 0;
    font-size: 12px;
    line-height: 2;
  }
}

.mv {
  padding-left: 40px;
}
@media (min-width: 48em) {
  .mv {
    padding-left: 150px;
  }
}
.mv__inner {
  position: relative;
}
.mv__image {
  padding-top: 128.95%;
  background-image: url("/assets/images/pages/index/mv_image_sm.jpg");
  background-size: cover;
}
@media (min-width: 48em) {
  .mv__image {
    width: 100%;
    height: 753px;
    padding-top: 0;
    background-image: url("/assets/images/pages/index/mv_image_md.jpg");
  }
}
.mv__image-text {
  display: none;
}
@media (min-width: 48em) {
  .mv__image-text {
    position: absolute;
    top: 273px;
    left: 8%;
    display: block;
  }
}
@media (min-width: 48em) {
  .mv__image-text img {
    width: 551px;
    height: 140px;
  }
}

.news {
  padding: 47px 25px 0;
}
@media (min-width: 48em) {
  .news {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 140px 0 65px;
  }
}
@media (min-width: 48em) {
  .news__contents {
    width: 100%;
    margin-left: 198px;
  }
}
.news__list {
  margin: 30px 0 0;
}
@media (min-width: 48em) {
  .news__list {
    margin: 0;
  }
}
.news__item {
  border-top: 1px solid #d9d9d9;
}
.news__item:last-child {
  border-bottom: 1px solid #d9d9d9;
}
.news__link {
  position: relative;
  display: block;
  padding: 17px 35px 21px 0;
}
@media (min-width: 48em) {
  .news__link {
    display: flex;
    align-items: center;
    padding: 27px 70px 28px 28px;
    transition: color 300ms;
  }
}
.news__link::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 14px;
  height: 8px;
  content: "";
  background-image: url("/assets/images/pages/common/icon_arrow.svg");
  transform: translateY(-50%);
}
@media (min-width: 48em) {
  .news__link:hover::before {
    right: 27px;
  }
}

@media (min-width: 48em) {
  .news__link::before {
    right: 32px;
    transition: right 300ms;
  }
}
@media (min-width: 48em) {
  .news__link:hover {
    color: #0052ad;
  }
}
.news__time {
  font-size: 10px;
  font-style: italic;
  letter-spacing: 0.07em;
}
@media (min-width: 48em) {
  .news__time {
    font-size: 12px;
  }
}
.news__text {
  margin: 4px 0 0;
  font-size: 12px;
}
@media (min-width: 48em) {
  .news__text {
    margin: 0 0 0 43px;
    font-size: 14px;
  }
}
.news__text-link {
  margin: 22px 0 0;
  font-size: 14px;
  text-align: right;
}
@media (min-width: 48em) {
  .news__text-link {
    margin: 28px 0 0;
  }
}
.news__text-link > a {
  position: relative;
}
.news__text-link > a::before {
  position: absolute;
  top: 8px;
  left: -18px;
  content: "";
  border-color: transparent transparent transparent #0052ad;
  border-style: solid;
  border-width: 3px 0 3px 5px;
}
@media (min-width: 48em) {
  .news__text-link > a::before {
    top: 7px;
    border-width: 4px 0 4px 6px;
  }
}
@media (min-width: 48em) {
  .news__text-link > a:hover {
    text-decoration: underline;
  }
}
@media (min-width: 48em) {
  .news .c-heading {
    margin-top: -13px;
  }
}

.property {
  padding: 52px 25px 0;
}
@media (min-width: 48em) {
  .property {
    position: relative;
    padding: 0;
  }
}
@media (min-width: 48em) {
  .property__inner {
    width: 1200px;
    margin: 0 auto;
  }
}
@media (min-width: 48em) {
  .property__contents {
    width: 374px;
    padding: 114px 0 0;
    margin-left: auto;
  }
}
@media (min-width: 48em) {
  .property__contents .c-heading {
    color: #fff;
  }
}
.property__text {
  margin: 20px 0 0;
  font-size: 14px;
  line-height: 1.85;
}
@media (min-width: 48em) {
  .property__text {
    margin: 100px 0 0;
    line-height: 2.42;
  }
}
.property__button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 45px;
  padding-bottom: 1px;
  margin: 23px 0 0;
  font-size: 14px;
  color: #0056b0;
  border-right: 1px solid #00adef;
  border-left: 1px solid #0052ad;
}
@media (min-width: 48em) {
  .property__button {
    width: 212px;
    height: 60px;
    margin: 50px 0 0;
    transition: all 300ms;
  }
}
.property__button::before, .property__button::after {
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  content: "";
  background-image: linear-gradient(90deg, #0052ad 0, #00adef 100%);
}
.property__button::before {
  top: 0;
}
.property__button::after {
  bottom: 0;
}
@media (min-width: 48em) {
  .property__button:hover {
    color: #fff;
  }
}
.property__button span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
@media (min-width: 48em) {
  .property__button:hover span::before {
    opacity: 1;
  }
}

@media (min-width: 48em) {
  .property__button span::before {
    position: absolute;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background-image: linear-gradient(90deg, #0052ad 0, #00adef 100%);
    opacity: 0;
    transition: opacity 300ms;
  }
}
.property__image {
  padding-top: 98.46%;
  margin: 35px 0 0;
  background-image: url("/assets/images/pages/index/property_image_sm.jpg");
  background-position: 50% 50%;
  background-size: cover;
}
@media (min-width: 48em) {
  .property__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 57%;
    height: 640px;
    padding: 0;
    margin: 0;
    background-image: url("/assets/images/pages/index/property_image_md.jpg");
  }
}

.recruit {
  padding: 58px 25px 0;
  overflow: hidden;
}
@media (min-width: 48em) {
  .recruit {
    position: relative;
    padding: 430px 0 128px;
  }
}
@media (min-width: 48em) {
  .recruit__inner {
    width: 1200px;
    margin: 0 auto;
  }
}
@media (min-width: 48em) {
  .recruit__contents {
    width: 50%;
  }
}
.recruit__heading {
  text-align: center;
}
@media (min-width: 48em) {
  .recruit__heading {
    text-align: left;
  }
}
.recruit__heading img {
  width: 55px;
}
@media (min-width: 48em) {
  .recruit__heading img {
    width: 76px;
  }
}
.recruit__copy {
  margin: 12px 0 0;
  font-size: 24px;
  line-height: 1.33;
  text-align: center;
}
@media (min-width: 48em) {
  .recruit__copy {
    margin: 33px 0 0;
    font-size: 32px;
    line-height: 1.46;
    text-align: left;
  }
}
.recruit__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 45px;
  padding-bottom: 1px;
  margin: 23px auto 0;
  font-size: 14px;
  color: #fff;
  background-image: linear-gradient(90deg, #0052ad 0, #00adef 100%);
}
@media (min-width: 48em) {
  .recruit__button {
    position: relative;
    z-index: 1;
    width: 212px;
    height: 60px;
    margin: 50px 0 0;
  }
}
@media (min-width: 48em) {
  .recruit__button:hover::before {
    opacity: 1;
  }
}

@media (min-width: 48em) {
  .recruit__button::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-image: linear-gradient(90deg, #0052ad 30%, #00adef 100%);
    opacity: 0;
    transition: opacity 450ms;
  }
}
.recruit__image {
  position: relative;
  height: 206px;
  margin: 52px -25px 0;
  background-color: #e2eef8;
}
@media (min-width: 48em) {
  .recruit__image {
    height: 502px;
  }
}
@media (min-width: 48em) {
  .recruit__image {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    margin: 0;
  }
}
.recruit__image::before {
  position: absolute;
  right: -16px;
  bottom: 0;
  width: 373px;
  height: 253px;
  content: "";
  background-image: url("/assets/images/pages/index/recruit_image.png");
  background-size: contain;
}
@media (min-width: 48em) {
  .recruit__image::before {
    right: 55px;
    width: 895px;
    height: 607px;
  }
}
.recruit__image img {
  width: 100%;
}