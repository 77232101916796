.property {
  padding: 52px 25px 0;

  @include mq(md) {
    position: relative;
    padding: 0;
  }

  &__inner {
    @include mq(md) {
      @include container();
    }
  }

  &__contents {
    @include mq(md) {
      width: 374px;
      padding: 114px 0 0;
      margin-left: auto;
    }

    .c-heading {
      @include mq(md) {
        color: #fff;
      }
    }
  }

  &__text {
    margin: 20px 0 0;
    font-size: 14px;
    line-height: 1.85;

    @include mq(md) {
      margin: 100px 0 0;
      line-height: 2.42;
    }
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 45px;
    padding-bottom: 1px;
    margin: 23px 0 0;
    font-size: 14px;
    color: #0056b0;
    border-right: 1px solid #00adef;
    border-left: 1px solid #0052ad;

    @include mq(md) {
      width: 212px;
      height: 60px;
      margin: 50px 0 0;
      transition: all 300ms;
    }

    &::before,
    &::after {
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      content: '';
      background-image: linear-gradient(90deg, rgb(0, 82, 173) 0, rgb(0, 173, 239) 100%); // stylelint-disable-line
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    &:hover {
      @include mq(md) {
        color: #fff;
      }
    }

    span {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      &::before {
        @at-root .property__button:hover span::before {
          @include mq(md) {
            opacity: 1;
          }
        }

        @include mq(md) {
          position: absolute;
          left: 0;
          z-index: -1;
          display: block;
          width: 100%;
          height: 100%;
          content: '';
          background-image: linear-gradient(90deg, rgb(0, 82, 173) 0, rgb(0, 173, 239) 100%); // stylelint-disable-line
          opacity: 0;
          transition: opacity 300ms;
        }
      }
    }
  }

  &__image {
    padding-top: 98.46%;
    margin: 35px 0 0;
    background-image: url('/assets/images/pages/index/property_image_sm.jpg');
    background-position: 50% 50%;
    background-size: cover;

    @include mq(md) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 57%;
      height: 640px;
      padding: 0;
      margin: 0;
      background-image: url('/assets/images/pages/index/property_image_md.jpg');
    }
  }
}
